import { ContactInformationModel, ProductModel } from "../types/type";
import ImgProduct1 from "../assets/images/product_images/product_1.jpg";
import ImgProduct2 from "../assets/images/product_images/product_2.jpg";
import ImgProduct3 from "../assets/images/product_images/product_3.jpg";
import ImgProduct4 from "../assets/images/product_images/product_4.jpg";
import ImgProduct5 from "../assets/images/product_images/product_5.jpg";
import ImgProduct6 from "../assets/images/product_images/product_6.jpg";
import ImgProduct7 from "../assets/images/product_images/product_7.jpg";
import ImgProduct8 from "../assets/images/product_images/product_8.jpg";
import ImgProduct9 from "../assets/images/product_images/product_9.jpg";
import ImgProduct10 from "../assets/images/product_images/product_10.jpg";
import { PriceTypeEnum } from "../enums/PriceTypeEnum";

export const productData: ProductModel[] = [
  {
    id: 1,
    name: "SPRINT(1966)",
    image: ImgProduct1,
    color: "Trắng",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 700000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 900000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1500000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1900000,
      },
    ],
  },
  {
    id: 2,
    name: "STANDARD(1960)",
    image: ImgProduct2,
    color: "Xanh",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 700000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 900000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1500000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1900000,
      },
    ],
  },
  {
    id: 3,
    name: "PX150(1966)",
    image: ImgProduct3,
    color: "Be",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 4,
    name: "PX150(1966)",
    image: ImgProduct4,
    color: "Xám",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 5,
    name: "PX150(1966)",
    image: ImgProduct5,
    color: "Đỏ",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 6,
    name: "EXCEL(1992)",
    image: ImgProduct6,
    color: "Xám",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 7,
    name: "PX150(1966)",
    image: ImgProduct7,
    color: "Cam",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 8,
    name: "SUPER(1992)",
    image: ImgProduct8,
    color: "MINT",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 700000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 900000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1500000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1900000,
      },
    ],
  },
  {
    id: 9,
    name: "PX150(1986)",
    image: ImgProduct9,
    color: "Bạc",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 600000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 800000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1400000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1800000,
      },
    ],
  },
  {
    id: 10,
    name: "MINI(1966)",
    image: ImgProduct10,
    color: "MINT",
    prices: [
      {
        priceType: PriceTypeEnum.ONE,
        price: 700000,
      },
      {
        priceType: PriceTypeEnum.TWO,
        price: 900000,
      },
      {
        priceType: PriceTypeEnum.FOUR,
        price: 1500000,
      },
      {
        priceType: PriceTypeEnum.SIX,
        price: 1900000,
      },
    ],
  },
];

export const contactInformation: ContactInformationModel = {
  hotline: "0981824558",
  email: "khaidoanvespa@gmail.com",
  website: "khaidoanvespa.site",
  websiteLink: "https://khaidoanvespa.site/",
  fbLink: "https://www.facebook.com/profile.php?id=61550956160146",
  igLink:
    "https://www.instagram.com/khaidoan.vespa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  tiktokLink: "https://www.tiktok.com/@khaidoan.vespa",
};

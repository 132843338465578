import { useRef } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import Layout from "./components/Layouts/LayoutComponent";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const ref = useRef<HTMLDivElement | null>(null);
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);
  // const ref4 = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Analytics />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                getHome={ref}
                getAbout={ref1}
                getStock={ref2}
                getContact={ref3}
              />
            }
          >
            <Route
              index
              element={
                <HomePage
                  getHome={ref}
                  getAbout={ref1}
                  getStock={ref2}
                  getContact={ref3}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

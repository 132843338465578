import { Box } from "@mui/material";
import React from "react";
import { ProductModel } from "../../types/type";

interface StockItemProps {
  item: ProductModel;
}

const StockItem: React.FC<StockItemProps> = ({ item }) => {
  

  return (
    
    <Box className="stock-item-container">
    <Box
      className="product_image"
      sx={{
        backgroundImage: `url(${item.image})`,
      }}
    ></Box>
    <h6 className="item-name">{item.name}</h6>
  </Box>
  );
};

export default StockItem;

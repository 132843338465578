import {
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProps } from "../../types/type";

const Layout: React.FC<LayoutProps> = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToRefWithOffset = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      const scrollPosition = ref.current.offsetTop - 64;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
      if (isMobile) {
        // Close the drawer on mobile after clicking a link
        setDrawerOpen(false);
      }
    }
  };

  const appBarContent = (
    <Toolbar
      sx={{
        justifyContent: "space-around",
      }}
    >
      <Link onClick={() => scrollToRefWithOffset(props.getHome)}>Home</Link>
      <Link onClick={() => scrollToRefWithOffset(props.getAbout)}>About</Link>
      <Link onClick={() => scrollToRefWithOffset(props.getStock)}>
        Catalogue
      </Link>
      <Link onClick={() => scrollToRefWithOffset(props.getContact)}>
        Contact
      </Link>
    </Toolbar>
  );

  const drawerContent = (
    <div>
      <List className="navbar-mobile">
        <ListItem button onClick={() => scrollToRefWithOffset(props.getHome)}>
          <p>Home</p>
        </ListItem>
        <ListItem button onClick={() => scrollToRefWithOffset(props.getAbout)}>
          <p>About</p>
        </ListItem>
        <ListItem button onClick={() => scrollToRefWithOffset(props.getStock)}>
          <p>Catalogue</p>
        </ListItem>
        <ListItem
          button
          onClick={() => scrollToRefWithOffset(props.getContact)}
        >
          <p>Contact</p>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <CssBaseline />
      {isMobile ? (
        // Show Drawer on mobile screens
        <>
          <AppBar
            className="navbar"
            elevation={0}
            component="nav"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
            {drawerContent}
          </Drawer>
        </>
      ) : (
        // Show AppBar on desktop screens
        <AppBar
          className="navbar"
          elevation={0}
          component="nav"
          color="inherit"
        >
          {appBarContent}
        </AppBar>
      )}
      <Toolbar />
      <Outlet />
    </div>
  );
};

export default Layout;

import React, { useState } from "react";
import { PriceModel, ProductModel } from "../../types/type";
import { Box, Chip, InputLabel, Stack, Typography } from "@mui/material";
import { PriceTypeEnum } from "../../enums/PriceTypeEnum";
import { convertToVND } from "../../utils/helper";

interface ProductDetailProps {
  product: ProductModel;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ product }) => {
  const [selectedPrice, setSelectedPrice] = useState<PriceModel>(
    product.prices[0]
  );

  return (
    <div>
      <Stack gap={4}>
        <h3 className="product_detail_name">{product.name}</h3>
        <Stack direction="row" gap={0.5}>
          <p className="product_detail_label">Màu:</p>
          <p className="product_detail_content"> {product.color}</p>
        </Stack>
        <Stack direction="row" gap={0.5} alignItems="center">
          <p className="product_detail_label">Giá thuê:</p>
          <Stack direction="row" gap={0.5}>
            {product.prices.map((e, index) => (
              <Chip
                key={index}
                sx={{
                  cursor: "pointer",
                }}
                color={selectedPrice === e ? "primary" : "default"}
                size="small"
                label={<p className="product_detail_content"> {e.priceType}</p>}
                onClick={() => {
                  setSelectedPrice(e);
                }}
              />
            ))}
          </Stack>
        </Stack>
        <h3 className="product_detail_name">
          {convertToVND(selectedPrice?.price)}
        </h3>
      </Stack>
    </div>
  );
};

export default ProductDetail;

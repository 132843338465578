import { Box, Container, Grid } from "@mui/material";
import React from "react";
import AboutImage from "../../assets/images/IMG_1647.jpg";

const AboutComponent: React.FC = () => {
  return (
    <div className="section2">
      <Container>
        <Grid container columnSpacing={10} rowSpacing={4}>
          <Grid item xs={12} sm={6} className="center">
            <Box className="center">
              <img
                style={{
                  boxShadow: "14px 14px #fff",
                }}
                src={AboutImage}
                alt="about_img"
                loading="lazy"
                width="70%"
                height="70%"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className="center">
            <div className="about-container title">
              <h3>About</h3>
              <div className="about-content">
                <span>
                  Vespa cổ sẽ là một điểm nhấn tuyệt vời nếu bạn cần cho
                </span>
                <br></br>
                <span>
                  chụp hình cưới, trưng bày cho sự kiên thương mại, rước
                </span>
                <br></br>
                <span>dâu theo kiểu Châu Âu cổ điển.</span>
                <br></br>
                <span>
                  Hãy để chúng tôi mang lại những trải nghiệm đáng nhớ
                </span>
                <br></br>
                <span>cho bạn qua chiếc xe này</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutComponent;

import React from "react";
import { ProductModel } from "../../types/type";
import {
  Box,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
} from "@mui/material";
import ProductDetail from "../ProductDetail/ProductDetail";
import CloseIcon from "@mui/icons-material/Close";

interface ProductDialogProps {
  open: boolean;
  handleClose: () => void;
  product: ProductModel | null;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  handleClose,
  product,
}) => {
  if (product === null) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Divider />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6} className="center">
          {/* <CardMedia
              component="img"
              sx={{
                height: "600px",
                // backgroundImage: `url(${product.image})`,
              }}
              image={product.image}
            /> */}
          <Hidden smDown>
            <CardMedia
              component="img"
              sx={{
                height: "600px",
              }}
              image={product.image}
            />
          </Hidden>
          <Hidden smUp>
            <CardMedia
              component="img"
              sx={{
                height: "264px",
              }}
              image={product.image}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DialogContent>
            <ProductDetail product={product} />
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ProductDialog;

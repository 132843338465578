import {
  Box,
  Container,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import StockItem from "../StockComponents/StockItem";
import { productData } from "../../data/data";
import ProductDialog from "../DialogComponents/ProductDialog";
import { ProductModel } from "../../types/type";

const StockComponent: React.FC = () => {
  const [itemsPerPage, setItemPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = productData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const handleItemPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Number(event.target.value);

    setItemPerPage(isNaN(newValue) ? 0 : newValue);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleItems = productData.slice(startIndex, startIndex + itemsPerPage);

  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(
    null
  );
  const [open, setOpen] = useState(false);

  const handleOpen = (selected: ProductModel) => {
    setOpen(true);
    setSelectedProduct(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="section">
      <Container>
        <div className="title center">
          <h3>Catalogue</h3>
        </div>
        <div className="stock-container">
          <Grid container spacing={3}>
            {visibleItems.map((e, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                className="center"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpen(e);
                }}
              >
                <StockItem item={e} />
              </Grid>
            ))}
          </Grid>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              marginTop: "32px",
            }}
          >
            {/* <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Typography>Page</Typography>
              <TextField
                size="small"
                onChange={handleItemPerPageChange}
                value={itemsPerPage}
              />
              <Typography>of {productData.length}</Typography>
            </Stack> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              color="standard"
              size="large"
            />
          </Stack>
        </div>
        {open && (
          <ProductDialog
            open={open}
            handleClose={handleClose}
            product={selectedProduct}
          />
        )}
      </Container>
    </div>
  );
};

export default StockComponent;

import { Box, Container, Grid, Hidden } from "@mui/material";
import React, { useEffect, useRef } from "react";
import MainTitle from "../TitleComponents/MainTitle";
import HeaderImage from "../../assets/images/IMG_4043.jpg";

const HeaderComponent: React.FC = () => {
  return (
    <div className="section">
      <Container>
        <Grid container columnSpacing={20}>
          <Grid item xs={12} sm={6} className="center">
            <MainTitle />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} className="center">
              <Box>
                <img
                  src={HeaderImage}
                  alt="header_img"
                  loading="lazy"
                  width="70%"
                  height="70%"
                />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default HeaderComponent;

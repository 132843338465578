import React from "react";
import HeaderComponent from "../../components/HomeComponents/HeaderComponent";
import AboutComponent from "../../components/HomeComponents/AboutComponent";
import StockComponent from "../../components/HomeComponents/StockComponent";
import ContactComponent from "../../components/HomeComponents/ContactComponent";
import { LayoutProps } from "../../types/type";

const HomePage: React.FC<LayoutProps> = (props) => {
  return (
    <div>
      <div ref={props.getHome}>
        <HeaderComponent />
      </div>
      <div ref={props.getAbout}>
        <AboutComponent />
      </div>
      <div ref={props.getStock}>
        <StockComponent />
      </div>
      <div ref={props.getContact}>
        <ContactComponent />
      </div>
    </div>
  );
};

export default HomePage;

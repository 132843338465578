import React from "react";

const MainTitle: React.FC = () => {
  return (
    <div className="logo-container logo">
      <h3>Khải Đoan</h3>
      <p>VESPA FOR RENT</p>
    </div>
  );
};

export default MainTitle;

import { Container, Grid, Link, Stack } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { contactInformation } from "../../data/data";

const ContactComponent: React.FC = () => {
  const { email, hotline, website, websiteLink, fbLink, igLink, tiktokLink } =
    contactInformation;

  return (
    <div className="section2">
      <Container>
        <div className="title center">
          <h3>Contact</h3>
        </div>
        <Grid container className="contact">
          <Grid item xs={8}>
            <p>Hotline: {hotline}</p>
            <p>Email: {email}</p>
            <p>
              Website:
              <Link href={websiteLink} target="_blank" color="inherit">
                {" "}
                {website}
              </Link>
            </p>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <Link href={fbLink} target="_blank" color="inherit">
                <FontAwesomeIcon icon={faFacebook} size="2xl" />
              </Link>
              <Link href={igLink} target="_blank" color="inherit">
                <FontAwesomeIcon icon={faInstagram} size="2xl" />
              </Link>
              <Link href={tiktokLink} target="_blank" color="inherit">
                <FontAwesomeIcon icon={faTiktok} size="2xl" />
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactComponent;
